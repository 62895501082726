import * as React from "react";
import {ASSETS} from "../common/images";
import {Link} from "gatsby";

const WebDevelopmentList = () => {
    const webDevelopmentProjects = [
        {
            projectImg: ASSETS.PORTFOLIO.AllInOne,
            frameworkName: '',
            projectName: 'All-In',
            description: 'Learn popular Modern Foreign Languages (MFL) and English as Additional Language (EAL). All-in offers access to..',
            url: 'all-in',
        },
        {
            projectImg: ASSETS.PORTFOLIO.SportGear,
            frameworkName: '',
            projectName: 'Sports Gear Swag',
            description: 'Sports Gear Swag (SGS) believes that people deserve high-quality sports gear and an effortless style. SGS crafts elite jerseys..',
            url: 'sports-gear-swag',
        },
        {
            projectImg: ASSETS.PORTFOLIO.Mavrik,
            frameworkName: '',
            projectName: 'Mav3rik',
            description: 'Mav3rik simplifies complexities and makes your business or work shine! It offers compelling UX and UI designs, bespoke mobile and web apps..',
            url: 'mav3rik',
        },
        {
            projectImg: ASSETS.PORTFOLIO.Auslankmatrimony,
            frameworkName: '',
            projectName: 'Auslankamatrimony',
            description: 'The aim of www.auslankamatrimony.com.au website is to achieve maximum happy marriages by discovering and matching prospective..',
            url: 'auslankamatrimony',
        },
        {
            projectImg: ASSETS.PORTFOLIO.Freshy,
            frameworkName: '',
            projectName: 'Freshy Helpline',
            description: 'Moving to a new place can be stressful where you need accommodation, jobs, vehicles, etc. Freshy Helpline in Melbourne supports.. ',
            url: 'freshy-helpline',
        },
        {
            projectImg: ASSETS.PORTFOLIO.CandyCoin,
            frameworkName: '',
            projectName: 'Candy Coin',
            description: 'Candy Coin brings you deliciously tangy, eye-popping Sour Belts bags. Not only this, while enjoying your juicy flavors, you can also win..',
            url: 'candy-coin',
        },
        {
            projectImg: ASSETS.PORTFOLIO.TadPole,
            frameworkName: '',
            projectName: 'Tadpole Rides',
            description: 'Tadpole applications and website caters to the riding requirements of the users while allowing them to earn residual income through referrals.',
            url: 'tadpole-rides',
        },
    ]
    return (
        <div className="container">
                <div className="row technology-tab-row">
                    {webDevelopmentProjects.map((row, index) => (
                        <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
                            <Link to={`/portfolio/project/${row.url}`} className="technology-common-tab-col figure-wrap">
                                <div className="technology-common-tab-header tab-img-effect">
                                    <div className="img-wrap">
                                        <img src={row.projectImg} alt="Web-Projects|GeekyBones"/>
                                    </div>
                                    <div className="text">
                                        <h3>{row.projectName}</h3>
                                    </div>
                                    <div className="line top"></div>
                                    <div className="line bottom"></div>
                                    <div className="line left"></div>
                                    <div className="line right"></div>
                                </div>
                                <div className="technology-common-tab-body">
                                    <div className="tab-body-heading">
                                        <h5>{row.projectName}</h5>
                                        <p>{row.frameworkName}</p>
                                    </div>
                                    <p>{row.description}</p>
                                    <div className="technology-common-tab-footer">
                                        <Link to={`/portfolio/project/${row.url}`}>View More</Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
        </div>
    )
}
export default WebDevelopmentList;
